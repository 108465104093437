import React from 'react'
import Helmet from 'react-helmet'
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon
} from 'react-share'

const Sns = props => {
  const { title, path } = props
  const rootPath = "https://www.my-ope.net"
  const url = `${rootPath}${path}`
  const size=36
  return (
    <>
      <div className="share-sns d-flex justify-content-end align-items-center">
        <div className="share-sns__btn">
          <FacebookShareButton url={url}>
            <FacebookIcon round size={size} />
          </FacebookShareButton>
        </div>
        <div className="share-sns__btn">
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon round size={size} />
          </TwitterShareButton>
        </div>
        <div className="share-sns__btn">
          <LineShareButton url={url} title={title}>
            <LineIcon round size={size} />
          </LineShareButton>
        </div>
        <div className="share-sns__btn" style={{marginTop:"5px"}}>
          <a className="hatena-bookmark-button" data-hatena-bookmark-lang="ja"
            data-hatena-bookmark-layout="touch" data-hatena-bookmark-width="34" data-hatena-bookmark-height="34" href={url}
            title={title} >
          </a>
        </div>
      </div>
    </>
  )
}

export default Sns
